<!-- 拣货单记录 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20 clearfix">
      <!-- 拣货单号 -->
      <FormItem>
        <span>拣货单号：</span>
        <Input class="iviewIptWidth250" placeholder="请输入" v-model="queryFrom.order_number"></Input>
      </FormItem>
      <!-- 拣货仓库 -->
      <FormItem class="marginLeft40">
        <span>拣货仓库：</span>
        <Select placeholder="请选择" v-model="queryFrom.warehouse_name" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in warehouseNameList" :key="index" :value="item.warehouse_name">{{ item.warehouse_name }}</Option>
        </Select>
      </FormItem>
      <!-- 拣货时间 -->
      <FormItem class="marginLeft40">
        <span>拣货时间：</span>
        <DatePicker type="daterange" placeholder="请选择" format="yyyy-MM-dd" class="iviewIptWidth250" @on-change="changeDate"></DatePicker>
      </FormItem>
      <!-- 拣货人 -->
      <FormItem class="marginLeft40 mr20">
        <span>拣货人：</span>
        <Select placeholder="请选择" v-model="queryFrom.create_by" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in pickerListist" :value="item.id" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <!-- 客户名称 -->
      <FormItem class="">
        <span>客户名称：</span>
        <Select placeholder="请输入" @on-change="customerStrchange" v-model="queryFrom.customer_str" clearable filterable class="iviewIptWidth250 marginRight60">
          <Option v-for="(item, index) in customIdList" :value="item.customer_name" :key="index">{{ item.customer_name }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <span class="marginLeft40">产品编号：</span>
        <i-input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.product_code"></i-input>
      </FormItem>
      <!-- 产品名称 -->
      <FormItem class="marginLeft40">
        <span>产品名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.product_str" @on-change="productChange" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in productList" :value="item" :key="index">{{ item }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <span>规格型号/SKU：</span>
        <Select class="iviewIptWidth250 marginRight60" multiple filterable clearable v-model="queryFrom.product_code_array" :max-tag-count="queryFrom.product_code_array.length == 1 ? 1 : 0">
          <Option v-for="(item, index) in modelList" :value="item" :key="index" :label="item">
            <span>{{ item }}</span>
            <!-- <span style="float: right; color: #ccc; margin-right: 20px;">{{ item }}</span> -->
          </Option>
        </Select>
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginLeft40">
        <span class="pageBtn finger btnSure" @click="query">查询</span>
        <span class="longTextBtn finger btnAdd marginLeft20" @click="exportMerge">
          <Spin style="display: inline-block;" v-show="Loading">
            <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          </Spin>
          合并导出
        </span>
      </FormItem>
    </Form>
    <!-- <Table :productList="listColumns" :productData="listData" :total="total" :pages="queryFrom" @change-page="changePage" totalText="条记录"></Table> -->
    <div class="clearfix tabDiv">
      <Spin fix v-if="tabloading"></Spin>
      <Table :columns="listColumns" :data="listData" highlight-row border :row-class-name="rowClassName"></Table>
      <div class="pages" v-if="listData !== null && listData.length > 0 && total">
        <Page v-if="queryFrom.page && total <= 60" :total="total" :current="queryFrom.page" @on-change="changePage" class="fl" />
        <Page v-if="queryFrom.page && total > 60" :total="total" :current="queryFrom.page" @on-change="changePage" show-elevator class="fl" />
        <div class="fr totalFont" v-if="total">共计{{ total }}条记录</div>
        <div class="fr totalFont" style="marginright: 30px;">
          总金额:
          <span style="color: #529dee;"> ￥{{ total_amount }} </span>
        </div>
        <div style="marginright: 30px;" class="fr totalFont">
          总数量:
          <span style="color: #529dee;">
            {{ total_quantity }}
          </span>
        </div>
      </div>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="chaxunFlag">
        <img style="margin-top: 100px;" src="../../../assets/images/erpNoData.png" />
        <p class="noDataImg">请选择查询条件进行查询</p>
      </div>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData !== null && listData.length == 0 && !chaxunFlag">
        <img style="margin-top: 100px;" src="../../../assets/images/tableNoData.png" />
      </div>
    </div>

    <Modal v-model="status" title="请选择模版" cancel-text @on-cancel="status = false" width="568">
      <div class="clearfix paddingBot40px">
        <div class="item">
          <span class="itemTitle">模版类型：</span>
          <Select placeholder="请选择" v-model="template_type" filterable class="iviewIptWidth307" @on-change="typeChange">
            <Option v-for="(item, index) in TemplateList" :value="item.typeID" :key="index">{{ item.typeName }}</Option>
          </Select>
        </div>
        <div class="item">
          <span class="itemTitle">模版名称：</span>
          <Select placeholder="请选择" v-model="template_id" filterable class="iviewIptWidth307" :disabled="!template_type">
            <Option v-for="(item, index) in typeList" :value="item.template_id" :key="index">{{ item.template_name }}</Option>
          </Select>
        </div>
        <!-- <div class="item">
          <span class="itemTitle">每页行数：</span>
          <InputNumber  :min="0" :precision="0" v-model="paging" :active-change="false"  class="iviewIptWidth307"></InputNumber>
        </div> -->
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="surePrint">确定</span>
      </div>
    </Modal>
  </div>
</template>

<script>
// import Table from '@/components/table'

export default {
  name: 'pickingListRecordConsign',
  // components: {
  //   Table,
  // },
  data() {
    return {
      chaxunFlag: true,
      total_amount: '',
      total_quantity: '',
      customID: '',
      customType: '',
      tabloading: false,
      Loading: false,
      TemplateList: [
        { typeName: '采购单', typeID: 1 },
        { typeName: '收货验收单', typeID: 2 },
        { typeName: '入库单', typeID: 3 },
        { typeName: '销售单', typeID: 4 },
        { typeName: '拣货单', typeID: 5 },
        { typeName: '出库单', typeID: 6 },
        { typeName: '采购记录', typeID: 7 },
        { typeName: '收货验收记录', typeID: 8 },
        { typeName: '入库记录', typeID: 9 },
        { typeName: '销售记录', typeID: 10 },
        { typeName: '拣货记录', typeID: 11 },
        { typeName: '出库记录', typeID: 12 },
      ],
      template_id: '',
      template_type: 5,
      typeList: [],
      status: false,
      // 客户名称下拉列表
      customIdList: [],
      productList: [],
      modelList: [],
      // 总条数
      total: 0,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          maxWidth: 90,
        },
        {
          title: '拣货单号',
          key: 'order_number',
          align: 'center',
          minWidth: 120,
          maxWidth: 250,
        },
        {
          title: '客户名称',
          key: 'customer_name',
          align: 'center',
          minWidth: 200,
          // maxWidth: 300,
        },
        {
          title: '拣货仓库',
          key: 'warehouse_name',
          align: 'center',
          minWidth: 120,
          maxWidth: 200,
        },
        {
          title: '拣货人',
          key: 'create_by',
          align: 'center',
          minWidth: 250,
          // maxWidth: 300,
        },
        {
          title: '拣货时间',
          key: 'create_time',
          align: 'center',
          minWidth: 200,
          maxWidth: 300,
        },
        {
          title: '数量',
          key: 'total_number',
          align: 'center',
          minWidth: 80,
          maxWidth: 120,
        },
        {
          title: '金额',
          key: 'total_amount',
          align: 'center',
          minWidth: 120,
          maxWidth: 200,
          render: (h, param) => {
            let str = this.$utils.formatMoney(param.row.total_amount, true)
            return h(
              'div',
              {},
              // param.row.total_amount
              str
            )
          },
        },
        {
          title: '备注',
          key: 'note',
          align: 'center',
          minWidth: 150,
          maxWidth: 300,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'TextAlign_lf',
                },
                param.row.note
              ),
            ])
          },
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 170,
          maxWidth: 250,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: param.row.recoil_status == 1 ? '#fa3e3b' : '#389afc',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.details(param.row)
                    },
                  },
                },
                '详情'
              ),
              h(
                'span',
                {
                  style: {
                    color: param.row.recoil_status == 1 ? '#fa3e3b' : '#389afc',
                    marginLeft: '20px',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.output(param.row.order_number)
                    },
                  },
                },
                '导出'
              ),
              h(
                'span',
                {
                  style: {
                    color: param.row.related_status == 3 || param.row.recoil_status == 1 ? '#fa3e3b' : '#389afc',
                    marginLeft: '20px',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      // this.$Message.warning('正在开发中')
                      this.clickId = param.row.warehouse_picking_id
                      this.status = true
                      this.getTemplate()
                    },
                  },
                },
                '打印'
              ),
            ])
          },
        },
      ],
      clickId: null,
      // 拣货人下拉
      pickerListist: [],
      // 拣货仓库下拉
      warehouseNameList: [],
      // 查询参数
      queryFrom: {
        product_code: '',
        page: 1,
        rows: 10,
        order_number: '',
        warehouse_name: '',
        create_by: '',
        create_time_start: '',
        create_time_end: '',
        product_str: '',
        product_name: '',
        product_code_array: [],
        specification_id_str: '',
      },
      paging: null,
    }
  },
  mounted() {
    // this.getGspWarehousePicking()
    this.permissionWarehouseList()
    this.getPickerListist()
    this.getcustomerList()
    this.getProductList()
    this.productChange()
  },
  methods: {
    // 获取最近的模板
    getTemplate() {
      // 获取模板名称
      this.$http.get(this.$apiConsign.templateInfo, { template_type: this.template_type }, false).then(res => {
        this.typeList = res.data
      })
      // 获取模板记录
      this.$http.get(this.$apiConsign.recentTemplate, { template_type: this.template_type }, false).then(res => {
        if (res.data) {
          this.template_id = res.data.template_id
        }
      })
    },
    surePrint() {
      let api_info = `${this.$apiConsign.gspWarehousePickingDetail.url}?warehouse_picking_id=${this.clickId}`
      // for (let objName in this.queryFrom) {
      //   console.log(objName)
      //   if (this.queryFrom[objName]) {
      //     api_info = api_info + `${objName}=${this.queryFrom[objName]}&`
      //   }
      // }
      if (!this.template_type) {
        this.$Message.warning('请选择模版类型')
        return
      }
      if (!this.template_id) {
        this.$Message.warning('请选择模版名称')
        return
      }
      this.$http.downFile(this.$apiConsign.templatePrint, { template_id: this.template_id, api_info: api_info, paging: this.paging }, true).then(res => {
        this.status = false
        this.$Message.success('导出成功')
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '拣货单记录.xlsx'
        aLink.click()
      })
    },
    // 发送请求获取 模版名称
    typeChange(e) {
      if (!e) return
      this.$http.get(this.$apiConsign.templateInfo, { template_type: e }, false).then(res => {
        this.typeList = res.data
      })
    },
    // 获取客户下拉接口
    getcustomerList() {
      this.$http.get(this.$apiConsign.warehousePickingListCustomer, false).then(res => {
        this.customIdList = res.data
      })
    },
    customerStrchange(e) {
      this.productList = []
      this.queryFrom.product_str = ''
      this.getProductList(e)
    },
    // 产品名称下拉
    getProductList(name) {
      if (name) {
        this.customIdList.forEach((item, index) => {
          if (item.customer_name == name) {
            this.customID = item.customer_id
            this.customType = item.customer_type
          }
        })
      }
      this.$http.get(this.$apiConsign.pickingPrductModelNames, { customer_id: this.customID, customer_type: this.customType }, false).then(res => {
        this.productList = res.data.product_name_list
      })
    },
    // 产品名称改变
    productChange(name) {
      this.queryFrom.product_code_array = []
      this.modelList = []
      this.$http.get(this.$apiConsign.pickingPrductModelNames, { product_name: name }, false).then(res => {
        this.modelList = res.data.model_name_list
      })
    },
    // 合并导出
    exportMerge() {
      let data = {
        order_number: this.queryFrom.order_number,
        product_code: this.queryFrom.product_code,
        create_time_start: this.queryFrom.create_time_start,
        create_time_end: this.queryFrom.create_time_end,
        create_by: this.queryFrom.create_by,
        warehouse_name: this.queryFrom.warehouse_name,
      }
      this.Loading = true
      this.$http.downFile(this.$apiConsign.gspWarehousePickingBatchExport, data).then(res => {
        this.Loading = false
        let blob = new Blob([res.data], { type: 'application/zip' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '拣货单记录合并导出表.zip'
        aLink.click()
      })
    },
    // 导出
    output(order_number) {
      let data = {
        order_number: order_number,
      }
      this.$http.downFile(this.$apiConsign.gspWarehousePickingExport, data).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '拣货单记录导出表.xlsx'
        aLink.click()
      })
    },
    // 反冲成功的行标红
    rowClassName(row, index) {
      if (row.recoil_status == 1) return 'redRow'
      return ''
    },
    // 查询
    query() {
      this.queryFrom.page = 1
      this.getGspWarehousePicking()
    },
    // 改变日期
    changeDate(e) {
      this.queryFrom.create_time_start = e[0]
      this.queryFrom.create_time_end = e[1]
    },
    // 分页改变
    changePage(e) {
      this.queryFrom.page = e
      this.getGspWarehousePicking()
    },
    // 详情
    details(row) {
      this.$router.push({
        path: '/detailsPickingListRecordConsign',
        query: {
          warehouse_picking_id: row.warehouse_picking_id,
          recoil_status: row.recoil_status,
        },
      })
    },
    // 拣货人下拉
    getPickerListist() {
      this.$http.get(this.$apiConsign.pickerListist).then(res => {
        this.pickerListist = res.data
      })
    },
    // 拣货仓库 下拉
    permissionWarehouseList() {
      this.$http.get(this.$apiConsign.permissionWarehouseList, { page: 1, rows: 1000 ,is_consign:1}, false).then(res => {
        this.warehouseNameList = res.data
      })
    },
    // 获取拣货单
    getGspWarehousePicking() {
      this.chaxunFlag = false
      this.tabloading = true
      if (this.queryFrom.product_code_array.length != 0) {
        this.queryFrom.model_name = this.queryFrom.product_code_array.join(',')
      } else {
        this.queryFrom.model_name = ''
      }
      this.queryFrom.product_name = this.queryFrom.product_str
      this.$http.get(this.$apiConsign.gspWarehousePicking, this.queryFrom, false).then(res => {
        this.tabloading = false
        this.listData = res.data.result
        this.total = res.data.total
        this.total_amount = res.data.total_amount
        this.total_quantity = res.data.total_quantity
        // 处理日期
        this.listData.forEach(item => {
          item.create_time = item.create_time ? this.$moment(item.create_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
        })
      })
    },
  },
}
</script>

<style scoped lang="less">
.mr20 {
  margin-right: 20px;
}

// 表格
.tabDiv {
  .totalFont {
    font-size: 14px;
  }
  .pages {
    margin-top: 10px;
    overflow: hidden;
    line-height: 32px;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  .itemTitle {
    width: 100px;
    color: #000;
    text-align: justify;
    height: 32px;
    line-height: 32px;
  }
  .itemTitle::after {
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
}
.noDataImg {
  position: absolute;
  top: 63%;
  left: 50%;
  transform: translateX(-54%) translateY(-50%);
  font-size: 22px;
  color: #ccc;
}
</style>
